
/* banner section */
.profile {
    height: 170px;
    width: 170px;
    border: 3px solid var(--blue);
    margin-top: -40px;
  }
  .my_card {
    text-align: center;
  }
  div span i.social_icon {
    background-color: white;
    padding: 5px;
    color: var(--blue);
    font-size: 25px;
    border-radius: 100%;
    width: 40px;
    border: 1px solid var(--blue);
    cursor: pointer;
    text-align: center;
  }
  div span i.social_icon:hover {
    background-color: var(--blue);
    color: white;
    transition: ease-in-out all 0dddfe0;
  }
  