/* Global CSS */

:root {
  --blue: #1e90ff;
  --bg_color: #dddfe0;
  --text_color: #666;
}
* {
  margin: 0;
  padding: 0;
}
i {
  cursor: pointer;
}
i:hover {
  color: var(--blue);
  transition: ease-in-out all 0.5s;
}
body {
  background-color: var(--bg_color);
  font-family: Arial, Helvetica, sans-serif;
  color: rgba(0, 0, 0, 0.6);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
}
a,
Nav,
NavLink {
  text-decoration: none;
}

.right {
  text-align: right;
}
.cont {
  background-color: #fff;
  margin-top: 80px;
}

nav ul li.nav-item a {
  border-bottom: 1px solid #fff;
  color: blue;
}
nav ul li.nav-item a:hover {
  overflow: hidden;
  border-bottom: 1px solid var(--blue);
  color: var(--blue);
}
.experience .content .conts h4,
.education .content .conts h4,
.education .content .conts h5 {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: bold;
}
@media (max-width: 768px) {
  /* border style  */
  .experience .content .right::before,
  .education .content .right::before {
    display: none;
  }
  .experience .content .right::after,
  .education .content .right::after {
    display: none;
  }
}

/* skills section  */
.skills .header h2,
.experience .header h2,
.education .header h2,
.project .header h2,
.contract h2 {
  display: block;
  text-align: center;
  font-size: 36px;
  background-color: var(--bg_color);
  font-family: "Times New Roman", Times, serif;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 60px;
  letter-spacing: 5px;
}

.skills .cards .card {
  height: 160px;
  text-align: center;
  cursor: pointer;
  box-shadow: 2px 2px 30px #999;
}

.skills .cards .card .card-img-top {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.skills .cards .card img {
  height: 70px;
  width: 70px;
}

.skills .cards .card .card-text {
  text-transform: capitalize;
}

/* experience */
.experience,
.education,
.project {
  text-align: center;
}
.experience .content,
.education .content,
.contract .content,
.project .content,
.project .cards .card {
  box-shadow: 3px 3px 30px #999;
  text-align: left;
}
.experience .content .conts,
.education .content .conts {
  padding: 50px 0;
  padding-left: 30px;
  border-left: 1px solid rgba(30, 144, 255, 0.2);
  margin-top: 0px !important;
  position: relative;
}

/* border style  */
.experience .content .right::before,
.education .content .right::before {
  content: " ";
  color: rgba(30, 144, 255, 0.2);
  background-color: #fff;
  padding: 0;
  margin: 0;
  width: 11px;
  height: 11px;
  border: 2px solid var(--blue);
  border-radius: 50%;

  position: absolute;
  top: -5px;
  right: -7px;
}
.experience .content .right::after,
.education .content .right::after {
  content: " ";
  color: rgba(30, 144, 255, 0.2);
  background-color: #fff;
  padding: 0;
  margin: 0;
  width: 11px;
  height: 11px;
  border: 2px solid var(--blue);
  border-radius: 50%;

  position: absolute;
  bottom: -5px;
  right: -7px;
}

/* exprerience section  */
.experience .content .right,
.education .content .right {
  padding: none;
  border: none;
  padding-right: 50px;
  border-right: 1px solid rgba(30, 144, 255, 0.2);
}

/* project section  */

.project .menus {
  list-style-type: none;
}
.project .menus a {
  text-decoration: none;
  color: var(--text_color);
  padding: 5px 8px;
  text-align: center;
  font-size: small;
  border-right: 1px solid rgba(102, 102, 102, 0.322);
}

.project .card {
  box-shadow: 3px 3px 30px #ddd;
}

.pcard {
  position: relative;
}
.pcard a {
  background-color: blue;
  bottom: 10px;
}

@media (max-width: 576px) {
  .project .cards .card {
    width: 95% !important;
  }
  .project .cards .card img {
    height: 14rem !important;
  }
}

.project .menus a:last-child {
  border: none;
}

/* contact form  */
.contract h2 {
  font-size: 30px;
  background-color: #fff;
}

/* footar section  */
footer a i {
  color: var(--text_color);
}
