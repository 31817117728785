
/* Navbar CSS */
.nav {
  padding: 0 !important;
}
/* The navigation bar */
.nav {
  overflow: hidden;
  background-color: #333;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
}
